<template>
  <div
    class="booksSet_css"
    :style="terminal === 'web' ? 'background: #fff;' : ''"
  >
    <div class="web_css" v-if="terminal === 'web'">
      <div class="web_tit">
        {{ similarQuery.similar_id ? "相似推荐" : "相关图书" }}
      </div>

      <div class="web_box">
        <div class="web_top">
          <div class="web_top_span">
            <el-dropdown
              v-if="demand_list.length > 1"
              @command="handleCommand"
              trigger="click"
              placement="bottom-start"
            >
              <el-button type="primary" class="dropdown_btn">
                <div class="btn_txt">{{ demand_con }}</div>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown" class="menu_css">
                <el-dropdown-item
                  :command="beforeHandleCommand(item)"
                  v-for="(item, index) in demand_list"
                  :key="index"
                  class="dropdown_li"
                  :class="demand_con == item.content ? 'dropdown_is' : ''"
                  >{{ item.content }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <div class="web_btn" @click="copy_s">复制</div>
            <div class="span_txt">勾选后点击可复制多个</div>
          </div>
          <div class="web_top_span">
            <div class="span_txt">
              提示：点击复制按钮，粘贴到对话框后发送即可
            </div>
          </div>
        </div>
        <div class="web_tab" style="width: 100%">
          <el-table
            v-loading="loading"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            ref="multipleTable"
            :data="booksData"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ fontSize: '14px', color: '#606266' }"
            style="width: 100%"
            :row-class-name="tableRowClassName"
          >
            <el-table-column
              type="selection"
              :selectable="selectable"
              width="35"
              align="center"
            >
            </el-table-column>
            <el-table-column label="书名" min-width="320">
              <template slot-scope="scope">
                {{ scope.row.title }}
              </template>
            </el-table-column>
            <el-table-column label="作者" min-width="130">
              <template slot-scope="scope">
                {{ scope.row.author.join(", ") }}
              </template>
            </el-table-column>
            <el-table-column label="出版社" min-width="280">
              <template slot-scope="scope">
                {{ scope.row.publisher }}
              </template>
            </el-table-column>
            <el-table-column label="指令" min-width="285">
              <template slot-scope="scope">
                <div class="instruction_txt">
                  {{ scope.row.instruction }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="128">
              <template slot-scope="scope">
                <div
                  class="copy_txt"
                  @click="copyDirectives(scope.row.instruction)"
                  v-if="scope.row.instruction"
                >
                  复制
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pag">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="booksQuery.page"
              background
              layout=" jumper, prev, pager, next"
              :total="total"
              v-if="total > 0"
              style="padding: 3px 5px"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="app_css" v-if="terminal === 'app'">
      <div class="app_box">
        <el-dropdown
          style="margin: auto; max-width: 300px"
          v-if="demand_list.length > 1"
          @command="handleCommand"
          trigger="click"
          placement="bottom"
        >
          <el-button type="primary" class="dropdown_btn">
            <div class="btn_txt">{{ demand_con }}</div>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" class="menu_css">
            <el-dropdown-item
              :command="beforeHandleCommand(item)"
              v-for="(item, index) in demand_list"
              :key="index"
              class="dropdown_li"
              :class="demand_con == item.content ? 'dropdown_is' : ''"
              >{{ item.content }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <div class="app_txt">提示：点击复制按钮，粘贴到对话框后发送即可</div>
        <div
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          style="width: 100%"
        >
          <div
            class="app_list"
            @scroll="handleScroll"
            v-if="booksData.length > 0"
            :style="
              demand_list.length > 1 ? ' height: calc(100vh - 120px)' : ''
            "
          >
            <!--  ref="scrollContainer" -->
            <!-- <div class="app_li"></div> -->
            <el-checkbox-group
              v-model="multipleSelection"
              @change="handleCheckedCitiesChange"
              style="width: 100%"
              class="checkbox_group_css"
            >
              <div
                v-for="(item, index) in booksData"
                :key="index"
                class="app_li"
                style="display: flex"
              >
                <el-checkbox
                  :label="item"
                  style="width: 30px"
                  class="checkbox_css"
                  :disabled="!item.instruction"
                >
                  &emsp;
                </el-checkbox>
                <div class="li_box">
                  <div class="li_tit">
                    <div
                      class="li_tit_"
                      :style="
                        item.instruction
                          ? 'width: calc(100% - 58px);'
                          : 'width: 100%;'
                      "
                    >
                      {{ item.title }}
                    </div>

                    <div
                      class="li_copy"
                      v-if="item.instruction"
                      @click="copyDirectives(item.instruction)"
                    >
                      复制
                    </div>
                  </div>
                  <div class="li_txt">
                    <div class="txt_left">作&emsp;者：<span>{{ item.author.join(", ") }}</span></div>
                    <!-- <div class="txt_right">
                      {{ item.author.join(", ") }}
                    </div> -->
                  </div>
                  <div class="li_txt">
                    <div class="txt_left">出版社：<span>{{ item.publisher }}</span></div>
                    <!-- <div class="txt_right">
                      {{ item.publisher }}
                    </div> -->
                  </div>
                  <div class="li_txt">
                    <div class="txt_left">指&emsp;令：<span style="color: var(---, #2970ff)">{{ item.instruction }}</span></div>
                    <!-- <div class="txt_right" style="color: var(---, #2970ff)">
                      
                    </div> -->
                  </div>
                </div>
              </div>
            </el-checkbox-group>
            <!--  -->
            <div v-if="isLoading" class="loading">
              加载中 <i class="el-icon-loading"></i>
            </div>
            <div
              v-if="booksData.length == total"
              class="loading"
              style="color: var(----, #aaadb5)"
            >
              没有更多了
            </div>
          </div>
          <div
            class="app_list"
            :style="
              demand_list.length > 1 ? ' height: calc(100vh - 120px)' : ''
            "
            v-if="booksData.length <= 0"
          >
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
      </div>
      <div class="app_bottom" v-if="booksData.length > 0">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          :disabled="disabledChick()"
          >选中（全部）</el-checkbox
        >
        <div class="copy_txt" @click="copy_s">复制</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import axios from "axios";
import Clipboard from "clipboard";
import $ from "jquery";
import wx from "weixin-js-sdk";
//
import { fullSetZhBook, similarRecommend } from "@/Api/userMessage";
export default {
  data() {
    return {
      terminal: "",
      loading: false,
      booksData: [],
      multipleSelection: [],
      total: 0,
      booksQuery: {
        size: 10,
        page: 1,
        TaoShuId: "",
        title: "",
      },
      checkAll: false,
      isIndeterminate: false,
      isLoading: false,
      similarQuery: {
        similar_id: "",
        demand_id: "",
      },
      demand_con: "",
      demand_list: [],
    };
  },
  created() {
    if (this.$route.query.TaoShuId) {
      this.booksQuery.TaoShuId = this.$route.query.TaoShuId;
      this.loading = true;
      this.booksList();
    } else if (this.$route.query.similar_id) {
      this.similarQuery.similar_id = this.$route.query.similar_id;
      this.similarList();
      //   this.booksQuery.TaoShuId =
      //     "78f027b8b4721589519665d244eb2481b15267c035a268217f868d7acd48abbdaf3aed2dc5d6af2278038024434aae26";
    }
  },
  mounted() {
    // 通过userAgent判断是否是手机端
    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    console.log(isAndroid, isiOS);
    if (isAndroid || isiOS) {
      this.terminal = "app";
    } else {
      this.terminal = "web";
    }
    console.log(this.terminal);
    webview.getSettings().setTextZoom(100);
    //检查小程序二维码的状态
  },
  methods: {
    handleScroll(event) {
      const container = event.target;

      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight - 1
      ) {
        if (this.booksData.length < this.total && this.isLoading == false) {
          this.isLoading = true;
          this.booksQuery.page++;
          this.booksList();
        }
      }
    },
    async booksList() {
      // fullSetZhBook, similarRecommend
      fullSetZhBook(this.booksQuery)
        .then((res) => {
          console.log(res);
          if (res.data.error_code == 0) {
            if (this.terminal == "web") {
              this.booksData = res.data.data.data;
            } else if (this.booksQuery.page == 1) {
              this.booksData = res.data.data.data;
            } else {
              this.booksData = [...this.booksData, ...res.data.data.data];
            }

            this.total = res.data.data.total;
          } else {
          }
          this.loading = false;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      //   $.ajax({
      //     url: "http://192.168.66.105:8019/api/full-set-zhBook/",
      //     type: "get",
      //     dataType: "jsonp", // 请求方式为jsonp
      //     jsonpCallback: "handleCallback", // 自定义回调函数名
      //     params: this.booksQuery,
      //   });
      //   this.loading = true;
      // try {
      //   const res = await axios.get("/api/full-set-zhBook/", {
      //     params: this.booksQuery,
      //     headers: {
      //       token: `EniWQNj5KOF8NuMR`, //
      //     },
      //   });
      //   console.log(res);
      //   if (res.data.error_code == 0) {
      //     if (this.terminal == "web") {
      //       this.booksData = res.data.data.data;
      //     } else if (this.booksQuery.page == 1) {
      //       this.booksData = res.data.data.data;
      //     } else {
      //       this.booksData = [...this.booksData, ...res.data.data.data];
      //     }

      //     this.total = res.data.data.total;
      //     //   this.$nextTick(() => {
      //     //     const container = this.$refs.scrollContainer;
      //     //     console.log(container);
      //     //     console.log(this.terminal);
      //     //     if (container) {
      //     //       container.addEventListener("scroll", this.handleScroll, {
      //     //         passive: true,
      //     //       });
      //     //     }
      //     //   });
      //   } else {
      //   }
      //   this.loading = false;
      //   this.isLoading = false;
      // } catch (error) {
      //   console.error(`请求失败`, error);
      // }
    },
    beforeHandleCommand(row) {
      return row;
    },
    handleCommand(command) {
      // console.log(command);
      this.similarQuery.demand_id = command.id;
      this.demand_con = command.content;
      this.similarList();
    },
    async similarList() {
      this.loading = true;
      // fullSetZhBook, similarRecommend
      similarRecommend(this.similarQuery)
        .then((res) => {
          console.log(res);
          if (res.data.error_code == 0) {
            this.demand_list = res.data.data.demand;
            if (!this.similarQuery.demand_id) {
              this.demand_con = this.demand_list[0].content;
            }
            // this.demand_list[0].content =
            //   "https://wenku.baidu.com/view/547980056629647d27284b73f242336c1fb93050.html?_wkts_=1733792965578&bdQuery=%E4%B8%AD%E5%A4%AE%E4%B8%80%E5%8F%B7%E6%96%87%E4%BB%B6PPT&needWelcomeRecommand=1";
            this.booksData = res.data.data.data;
            this.total = res.data.data.data.length;
          } else {
          }
          this.loading = false;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      // try {
      //   const res = await axios.get("/api/similar-recommend/", {
      //     params: this.similarQuery,
      //     headers: {
      //       token: `EniWQNj5KOF8NuMR`, //
      //     },
      //   });
      //   console.log(res);
      //   if (res.data.error_code == 0) {
      //     this.demand_list = res.data.data.demand;
      //     if (!this.similarQuery.demand_id) {
      //       this.demand_con = this.demand_list[0].content;
      //     }
      //     // this.demand_list[0].content =
      //     //   "https://wenku.baidu.com/view/547980056629647d27284b73f242336c1fb93050.html?_wkts_=1733792965578&bdQuery=%E4%B8%AD%E5%A4%AE%E4%B8%80%E5%8F%B7%E6%96%87%E4%BB%B6PPT&needWelcomeRecommand=1";
      //     this.booksData = res.data.data.data;
      //     this.total = res.data.data.data.length;
      //   } else {
      //   }
      //   this.loading = false;
      //   this.isLoading = false;
      // } catch (error) {
      //   console.error(`请求失败`, error);
      // }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.read == 0) {
        // return "read_css"
        return "";
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.booksQuery.page = val;
      this.loading = true;
      this.booksList();
    },
    // 每页显示条数
    handleSizeChange(val) {
      this.booksQuery.size = val;
      this.loading = true;
      this.booksList();
    },
    copyDirectives(text) {
      const clipboard = new Clipboard(".web_btn", {
        text: () => text,
      });
      clipboard.on("success", () => {
        if (this.terminal == "app") {
          Toast.success("复制成功");
        } else {
          this.$message({
            message: "复制成功",
            type: "success",
          });
        }

        clipboard.destroy();
      });
      clipboard.on("error", () => {
        clipboardError();
        clipboard.destroy();
      });
      clipboard.onClick(event);

      //   let oInput = document.createElement("input");
      //   oInput.value = text;
      //   document.body.appendChild(oInput);
      //   oInput.select(); // 选择对象;
      //   console.log(oInput.value);
      //   // 执行浏览器复制命令
      //   /// 复制命令会将当前选中的内容复制到剪切板中
      //   /// 如这里构建的 Input标签
      //   document.execCommand("Copy");
      //   this.$message({
      //     message: "复制成功",
      //     type: "success",
      //   });
      //   ///复制成功后再将构造的标签 移除
      //   oInput.remove();
    },
    copy_s() {
      //   console.log(this.multipleSelection);
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        let text = this.multipleSelection
          .map((item) => item.instruction)
          .join("\n");
        this.copyDirectives(text);
      } else {
        if (this.terminal == "app") {
          Toast.fail("请勾选数据");
        } else {
          this.$message({
            message: "请勾选数据",
            type: "warning",
          });
        }
      }
    },
    selectable(row) {
      //   console.log(row)
      if (row.instruction) {
        return true;
      } else {
        return false;
      }
    },
    handleCheckAllChange(val) {
      let check_ = this.booksData.filter((item) => item.instruction);
      this.multipleSelection = val ? check_ : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let check_ = this.booksData.filter((item) => item.instruction);
      let checkedCount = value.length;
      this.checkAll = checkedCount === check_.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < check_.length;
    },
    disabledChick() {
      let check_ = this.booksData.filter((item) => item.instruction);
      if (check_.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  beforeDestroy() {
    const container = this.$refs.scrollContainer;
    if (container) {
      container.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style   lang="less" scoped>
.booksSet_css {
  width: 100%;
  font-family: "PingFang SC";

  min-height: 100vh;
}
.web_css {
  display: flex;
  max-width: 1199px;
  //   height: 884px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  margin: auto;
  padding: 61px 20px 20px 20px;
  .web_tit {
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }
  .web_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    .web_top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      align-self: stretch;
      .web_top_span {
        display: flex;
        align-items: center;
        gap: 15px;
        .web_btn {
          display: flex;
          padding: 8px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: #f5f5f5;
          cursor: pointer;
        }
        .span_txt {
          color: var(----, #aaadb5);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
}
.web_tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 52px;
  align-self: stretch;
  border-radius: 8px;
  // background: #FFF;
  .pag {
    display: flex;
    padding: 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
}
.instruction_txt {
  color: var(---, #2970ff);
}
.copy_txt {
  color: #e2426b;
  cursor: pointer;
}
.app_box {
  padding: 12px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  .app_txt {
    color: var(----, #aaadb5);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width:95%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .app_list::-webkit-scrollbar {
    display: none;
  }
  .app_list {
    width: 100%;
    height: calc(100vh - 80px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 0 50px 0;
    .checkbox_group_css {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
    }

    .app_li {
      width: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;

      padding: 14px 8px 14px 12px;
      .checkbox_css {
      }

      .li_box {
        gap: 8px;
        flex: 1 0 0;
        width: calc(100% - 50px);
        .li_tit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          .li_tit_ {
            color: var(----, #16181b);
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
            white-space: nowrap; //文本强制不换行；
            text-overflow: ellipsis; //文本溢出显示省略号；
            overflow: hidden; //溢出的部分隐藏；
          }
          .li_copy {
            display: flex;
            padding: 5px 16px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 25px;
            background: rgba(226, 66, 107, 0.2);
            color: #e2426b;
            font-size: 12px;
            line-height: 150%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .li_txt {
          display: flex;
          align-items: flex-start;
          gap: 4px;
          align-self: stretch;
          .txt_left,
          .txt_right {
            color: var(----, #aaadb5);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .txt_left {
            // width: 56px;
          }
        }
      }
    }
  }
}
.checkbox_css {
  /deep/ .el-checkbox__label {
    // width: calc(100% - 35px);
  }
}
.app_bottom {
  display: flex;
  display: flex;
  height: 64px;
  padding: 0px 14px;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;

  /deep/ .el-checkbox__label {
    padding-left: 0px;
  }
}
.loading {
  text-align: center;
  padding-top: 10px;
  color: #2970ff;
}
.dropdown_is {
  color: #b14a75;
}
.dropdown_btn {
  /deep/ span {
    width: 150px;
    display: flex;
    align-items: center;
  }

  .btn_txt {
    width: calc(100% - 10px);
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}
.dropdown_li {
  line-height: 20px;
  padding: 8px 20px;
  white-space: pre-wrap;
  word-break: break-all;
}
html {
  -webkit-text-size-adjust: 100% !important;
}
</style>
<style   lang="less">
.menu_css {
  max-width: 300px;
}
</style>
<style >
html {
  -webkit-text-size-adjust: 100% !important;
}
</style>
